<template>
  <main>
    <section class="bg-body-1 flex flex-col lg:grid grid-cols-2 items-center lg:max-w-screen-xl lg:mx-auto lg:rounded-2xl mt-11 py-20 lg:py-32">
      <div class="text-white flex flex-col gap-10 justify-center px-5 xl:px-14">
        <h1 class="text-4xl font-bold" style="line-height: 70px;">Prodejte svou nemovitost rychleji, bezpečněji a<br /> s vyšším ziskem</h1>
        <p>Cílem aplikace MakléřeNechci je prodej nemovitostí zjednodušit a učinit ho transparentnější i pro ty, kteří se obchodováním s nemovitostmi nezabývají.</p>
        <div class="flex justify-center lg:justify-start items-center gap-5">
          <router-link to="/nova-nemovitost" class="bg-body-3 px-5 py-4 rounded-md">Vytvořte nemovitost</router-link>
        </div>
      </div>
      <div class="mt-10 w-4/5 lg:w-11/12 xl:w-full lg:mt-0">
        <img width="550" class="rounded-lg" src="/images/working-lady.png" />
      </div>
    </section>
    <section class="flex flex-col justify-center gap-16 lg:max-w-screen-xl lg:mx-auto px-5 py-20 lg:px-0 lg:py-40">
      <h2 class="text-4xl text-center font-bold">Ušetříte desítky i stovky tisíc za provizi <br /> obchodníkům s nemovitostmi</h2>
      <ul class="flex flex-col items-center lg:items-start lg:flex-row gap-10 justify-center">
        <li class="flex flex-col gap-4 lg:gap-7 text-center" style="width: 300px;">
          <img class="mx-auto" height="35" src="/images/price-tag.png" />
          <h4 class="text-2xl font-bold">Prodáte svou nemovitost za co nejvyšší cenu</h4>
          <p>Za prodej nemovitosti nezaplatíte žádnou provizi z kupní ceny.</p>
        </li>
        <li class="flex flex-col gap-4 lg:gap-7 text-center" style="width: 300px;">
          <img class="mx-auto" height="35" src="/images/safe-house.png" />
          <h4 class="text-2xl font-bold">Rychlý a bezpečný <br />prodej</h4>
          <p>Jednoduchý a bezpečný proces prodeje nemovitostí krok za krokem.</p>
        </li>
        <li class="flex flex-col gap-4 lg:gap-7 text-center" style="width: 300px;">
          <img class="mx-auto" height="35" src="/images/transparent-selling.png" />
          <h4 class="text-2xl font-bold">Transparentnejší prodej nemovitostí</h4>
          <p>Transparentnější prodej i pro ty, kteří se obchodováním s nemovitostmi nezabývají.</p>
        </li>
      </ul>
      <router-link to="/nova-nemovitost" class="bg-body-3 px-5 py-3 text-white rounded-md mx-auto" style="width: fit-content;">Vytvořte nemovitost</router-link>
    </section>
    <section class="flex flex-col lg:max-w-screen-xl lg:mx-auto px-5 xl:px-0 py-10 lg:py-24">
      <h2 class="text-4xl font-bold text-center mb-5">Prodej s aplikací MakléřeNechci <br /> versus bez aplikace</h2>
      <div class="flex flex-col items-center mb-16">
        <p>Díky aplikaci MakléřeNechci svou nemovitost prodáte za dobrou cenu, bezpečně a bez začátečnických chyb.</p>
        <p>Zkrátka budete působit jako profesionál. Navíc ušetříte za provizi realitnímu makléři.</p>
      </div>
      <div class="flex flex-col mx-auto lg:grid grid-cols-2 gap-16 mb-20">
        <div>
          <h3 class="text-2xl font-bold mb-7">Prodej s aplikací MakléřeNechci:</h3>
          <ul class="list">
            <li>
              <div class="w-5 h-5 bg-body-3 rounded-full flex justify-center items-center">
                <img width="10" src="/images/checkmark.png" />
              </div>
              Máte plán.
            </li>
            <li>
              <div class="w-5 h-5 bg-body-3 rounded-full flex justify-center items-center">
                <img width="10" src="/images/checkmark.png" />
              </div>
              Shromáždíte všechny potřebné informace.
            </li>
            <li>
              <div class="w-5 h-5 bg-body-3 rounded-full flex justify-center items-center">
                <img width="10" src="/images/checkmark.png" />
              </div>
              Rozdělíte si prodej nemovitosti na menší úkoly.
            </li>
            <li>
              <div class="w-5 h-5 bg-body-3 rounded-full flex justify-center items-center">
                <img width="10" src="/images/checkmark.png" />
              </div>
              Do obchodu nezapojujete emoce.
            </li>
            <li>
              <div class="w-5 h-5 bg-body-3 rounded-full flex justify-center items-center">
                <img width="10" src="/images/checkmark.png" />
              </div>
              Neprodává ani konkrétní podlahovou krytinu ani dlaždičky v koupelně.
            </li>
            <li>
              <div class="w-5 h-5 bg-body-3 rounded-full flex justify-center items-center">
                <img width="10" src="/images/checkmark.png" />
              </div>
              Prodáváte sen kupujícího.
            </li>
            <li>
              <div class="w-5 h-5 bg-body-3 rounded-full flex justify-center items-center">
                <img width="10" src="/images/checkmark.png" />
              </div>
              Nemovitost nachystáte tak, že oslovíte co nejširší skupinu lidí.
            </li>
            <li>
              <div class="w-5 h-5 bg-body-3 rounded-full flex justify-center items-center">
                <img width="10" src="/images/checkmark.png" />
              </div>
              Před zveřejněním nabídky připravíte všechny potřebné dokumenty.
            </li>
            <li>
              <div class="w-5 h-5 bg-body-3 rounded-full flex justify-center items-center">
                <img width="10" src="/images/checkmark.png" />
              </div>
              Jakmile zveřejní inzerci, jste připraveni uzavřít obchod ke spokojenosti své i kupujícího.
            </li>
          </ul>
        </div>
        <div>
          <h3 class="text-2xl font-bold mb-7">Prodej bez aplikace MakléřeNechci:</h3>
          <ul class="list">
            <li>
              <div class="w-5 h-5 flex justify-center items-center rounded-full" style="background-color: #EB2004;">
                <img width="10" src="/images/x-cross.png" />
              </div>
              Nemáte žádný strategický plán.
            </li>
            <li>
              <div class="w-5 h-5 flex justify-center items-center rounded-full" style="background-color: #EB2004;">
                <img width="10" src="/images/x-cross.png" />
              </div>
              Nerozumíte související administrativě.
            </li>
            <li>
              <div class="w-5 h-5 flex justify-center items-center rounded-full" style="background-color: #EB2004;">
                <img width="10" src="/images/x-cross.png" />
              </div>
              Působíte neprofesionálně a odrazujete kupce.
            </li>
            <li>
              <div class="w-5 h-5 flex justify-center items-center rounded-full" style="background-color: #EB2004;">
                <img width="10" src="/images/x-cross.png" />
              </div>
              Nemáte znalost cenotvorby.
            </li>
            <li>
              <div class="w-5 h-5 flex justify-center items-center rounded-full" style="background-color: #EB2004;">
                <img width="10" src="/images/x-cross.png" />
              </div>
              Používáte taktiku “nastřelení” ceny a následného snižování.
            </li>
            <li>
              <div class="w-5 h-5 flex justify-center items-center rounded-full" style="background-color: #EB2004;">
                <img width="10" src="/images/x-cross.png" />
              </div>
              Snižováním se však rychle dostanou pod reálnou hodnotu nemovitosti.
            </li>
            <li>
              <div class="w-5 h-5 flex justify-center items-center rounded-full" style="background-color: #EB2004;">
                <img width="10" src="/images/x-cross.png" />
              </div>
              Nedomyšlená inzerce, které mohou využít realitní makléři.
            </li>
            <li>
              <div class="w-5 h-5 flex justify-center items-center rounded-full" style="background-color: #EB2004;">
                <img width="10" src="/images/x-cross.png" />
              </div>
              Makléři si počkají až cena klesne, nemovitost koupí a po čase prodají výrazně dráž.
            </li>
          </ul>
        </div>
      </div>
      <router-link to="/nova-nemovitost" class="bg-body-3 text-white px-5 py-3 rounded-md mx-auto" style="width: fit-content;">Vytvořte nemovitost</router-link>
    </section>
    <section class="flex flex-col lg:max-w-screen-xl lg:mx-auto px-5 xl:px-0 py-10 lg:py-24">
      <h2 class="text-4xl font-bold text-center mb-10 lg:mb-24">Modelová kalkulace <br /> Cena bytu 5 000 000 Kč</h2>
      <div class="flex flex-col lg:grid grid-cols-2 gap-8 mb-20">
        <div class="border border-body-1 rounded-md px-5 py-5" style="background-color: #f3f4fd;">
          <h3 class="text-4xl font-bold text-center py-8">Prodej s aplikací</h3>
          <ul class="list-sell">
            <li>
              <p>Profesionální odhad nemovitosti</p>
              <p class="font-bold">Zdarma</p>
            </li>
            <li>
              <p>List vlastnictví</p>
              <p class="font-bold">100 Kč + 50 Kč/strana</p>
            </li>
            <li>
              <p>Příprava domácnosti na focení</p>
              <p class="font-bold">2 500 Kč</p>
            </li>
            <li>
              <p>Profesionální fotografie</p>
              <p class="font-bold">3 000 Kč</p>
            </li>
            <li>
              <p>Profesionální video</p>
              <p class="font-bold">4 500 Kč</p>
            </li>
            <li>
              <p>3D Model</p>
              <p class="font-bold">1 500 Kč</p>
            </li>
            <li>
              <p>Možnost nahrát své vlastní fotografie</p>
              <p class="font-bold">Zdarma</p>
            </li>
            <li>
              <p>Možnost nahrát své vlastní video</p>
              <p class="font-bold">Zdarma</p>
            </li>
            <li>
              <p>Webová stránka</p>
              <p class="font-bold">Zdarma</p>
            </li>
            <li>
              <p>Základní inzerce</p>
              <p class="font-bold">890 Kč</p>
            </li>
            <li>
              <p>Topování</p>
              <p class="font-bold">100 Kč</p>
            </li>
            <li>
              <p>Rezervační smlouva</p>
              <p class="font-bold">3 000 Kč</p>
            </li>
            <li>
              <p>Kupní smlouva</p>
              <p class="font-bold">3 000 Kč</p>
            </li>
            <li>
              <p>Konzultace s advokátem</p>
              <p class="font-bold">600 Kč / Hod.</p>
            </li>
            <li>
              <p>Návrh na vklad nemovitosti</p>
              <p class="font-bold">2000 Kč</p>
            </li>
          </ul>
          <div class="flex justify-between items-center py-7">
            <p class="text-2xl">Náklady celkem:</p>
            <p class="text-5xl font-bold text-red-500">-19 640 Kč</p>
          </div>
        </div>
        <div class="border border-body-1 rounded-md px-5 py-5 h-fit" style="background-color: #f3f4fd;">
          <h3 class="text-4xl font-bold text-center py-8">Prodej s makléřem</h3>
          <ul class="list-sell">
            <li>
              <p>První seznámení</p>
              <p class="font-bold">0 Kč</p>
            </li>
            <li>
              <p>Nacenění nemovitosti</p>
              <p class="font-bold">0 Kč</p>
            </li>
            <li>
              <p>Profesionální fotograf, video, dron, virtuální prohlídka, <br /> návrh interieru, vizualizace, popis inzerátu </p>
              <p class="font-bold">0 Kč</p>
            </li>
            <li>
              <p>Inzerce na realitních webech, sociálních sítích, nemovitosti</p>
              <p class="font-bold">0 Kč</p>
            </li>
            <li>
              <p>Prohlídky a komunikace se zájemci</p>
              <p class="font-bold">0 Kč</p>
            </li>
            <li>
              <p>Rezervační smlouvy, kupní smlouvy, ověření smluv,<br /> převod nemovitostí</p>
              <p class="font-bold">0 Kč</p>
            </li>
            <li>
              <p>Advokátní úschova</p>
              <p class="font-bold">0 Kč</p>
            </li>
            <li>
              <p>Předání nemovitosti a převod energií</p>
              <p class="font-bold">0 Kč</p>
            </li>
            <li>
              <p>Provize realitní kanceláři</p>
              <p class="font-bold">250 000 Kč</p>
            </li>
          </ul>
          <div class="flex justify-between items-center py-7">
            <p class="text-2xl">Náklady celkem:</p>
            <p class="text-5xl font-bold text-red-500">-250 000 Kč</p>
          </div>
        </div>
      </div>
      <router-link to="/nova-nemovitost" class="bg-body-3 text-white px-5 py-3 rounded-md mx-auto" style="width: fit-content;">Vytvořte nemovitost</router-link>
    </section>
    <section class="flex flex-col py-24 bg-body-1">
      <div class="lg:max-w-screen-xl lg:mx-auto text-white">
        <h2 class="text-4xl text-center font-bold">Jak MakléřeNechci funguje?</h2>
        <p class="text-xl text-center mt-6 mb-20">Aplikace MakléřeNechci vás provede celým procesem prodeje domu či bytu a dovede vás <br class="hidden lg:inline" />až ke kýženému cíli – prodat svůj majetek za co nejvyšší cenu, rychle a bezpečně</p>
        <div class="flex flex-col gap-20 lg:gap-40 relative px-5 xl:px-0">
          <div class="hidden xl:block absolute" style="left: 570px;">
            <div class="w-10 h-10 bg-body-3 rounded-full flex justify-center items-center text-xl">1</div>
            <div class="bg-body-3 mx-auto" style="height: 450px; width: 2px;" />
            <div class="w-10 h-10 bg-body-3 rounded-full flex justify-center items-center text-xl mt-4">2</div>
            <div class="bg-body-3 mx-auto mt-4" style="height: 430px; width: 2px;" />
            <div class="w-10 h-10 bg-body-3 rounded-full flex justify-center items-center text-xl mt-4">3</div>
            <div class="bg-body-3 mx-auto mt-4" style="height: 430px; width: 2px;" />
            <div class="w-10 h-10 bg-body-3 rounded-full flex justify-center items-center text-xl mt-4">4</div>
            <div class="bg-body-3 mx-auto mt-4" style="height: 430px; width: 2px;" />
            <div class="w-10 h-10 bg-body-3 rounded-full flex justify-center items-center text-xl mt-4">5</div>
            <div class="bg-body-3 mx-auto mt-4" style="height: 270px; width: 2px;" />
          </div>
          <div class="flex flex-col-reverse lg:grid grid-cols-2 gap-16 lg:gap-28">
            <div class="px-10 py-8 rounded-xl" style="background-color: #3842f8;">
              <img class="rounded-xl" width="450" src="/images/guide.png" />
            </div>
            <div class="flex flex-col">
              <div class="flex flex-col gap-6 pb-5 border-b border-b-white">
                <h3 class="text-3xl font-bold">Průvodce</h3>
                <p>Zde se dozvíte vše potřebné o procesu<br /> prodeje nemovitosti.</p>
              </div>
              <ul class="flex flex-col gap-4 mt-10">
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Přehledná a srozumitelná charakteristika <br /> jednotlivých prodejních fází
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Profesionální šablony pro popis nemovitosti do inzerce
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Praktické rady ve videích s odpověďmi <br />na nejčastěji kladené otázky
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col lg:grid grid-cols-2 gap-16 lg:gap-28">
            <div class="flex flex-col">
              <div class="flex flex-col gap-6 pb-5 border-b border-b-white">
                <h3 class="text-3xl font-bold">Strategie</h3>
                <p>V sekci strategie si připravíte veškeré dokumenty <br />potřebné k prodeji nemovitosti.</p>
              </div>
              <ul class="flex flex-col gap-4 mt-10">
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Cenová strategie s nástrojem pro nastavení <br />ceny nemovitosti
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Cenová mapa k Vaší nemovitosti
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Příprava prodejních dokumentů
                </li>
              </ul>
            </div>
            <div class="px-10 py-8 rounded-xl" style="background-color: #3842f8;">
              <img class="rounded-xl" width="450" src="/images/strategy.png" />
            </div>
          </div>
          <div class="flex flex-col-reverse lg:grid grid-cols-2 gap-16 lg:gap-28">
            <div class="px-10 py-8 rounded-xl" style="background-color: #3842f8;">
              <img class="rounded-xl" width="450" src="/images/preparing.png" />
            </div>
            <div class="flex flex-col">
              <div class="flex flex-col gap-6 pb-5 border-b border-b-white">
                <h3 class="text-3xl font-bold">Příprava</h3>
                <p>Sekce příprava se zabývá přípravou grafických <br />a audiovizuálních podkladů.</p>
              </div>
              <ul class="flex flex-col gap-4 mt-10">
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Seznam profesionálů, kteří vám pomohou s interiérovým<br />designem, focením nebo tvorbou videa
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  3D model nemovitosti
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Přidání vlastních fotografií nebo videí
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col lg:grid grid-cols-2 gap-16 lg:gap-28">
            <div class="flex flex-col">
              <div class="flex flex-col gap-6 pb-5 border-b border-b-white">
                <h3 class="text-3xl font-bold">Inzerce</h3>
                <p>Jedním kliknutím zveřejníte svůj inzerát s prodejem<br /> nemovitosti na významné realitní servery.</p>
              </div>
              <ul class="flex flex-col gap-4 mt-10">
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Jedním kliknutím zveřejníte svůj inzerát s prodejem <br />nemovitosti na významné realitní servery.
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Různé inzertní balíčky
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Topování inzerátu
                </li>
              </ul>
            </div>
            <div class="px-10 py-8 rounded-xl" style="background-color: #3842f8;">
              <img class="rounded-xl" width="450" src="/images/advertisement.png" />
            </div>
          </div>
          <div class="flex flex-col-reverse lg:grid grid-cols-2 gap-16 lg:gap-28">
            <div class="px-10 py-8 rounded-xl" style="background-color: #3842f8;">
              <img class="rounded-xl" width="450" src="/images/law-documentation.png" />
            </div>
            <div class="flex flex-col">
              <div class="flex flex-col gap-6 pb-5 border-b border-b-white">
                <h3 class="text-3xl font-bold">Právní dokumentace</h3>
                <p>S aplikací MakléřeNechci máte k dispozici všechny <br />potřebné právní dokumenty.</p>
              </div>
              <ul class="flex flex-col gap-4 mt-10">
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Základní vzory smlouv na rezervaci i prodej nemovitosti
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Schůzka s prověřenou advokátní kanceláří
                </li>
                <li class="flex gap-3">
                  <div class="border border-white w-5 h-5 flex justify-center items-center rounded-full">
                    <img width="10" src="/images/checkmark.png" />
                  </div>
                  Autorizovaní advokáti s dlouholetými zkušenostmi <br />s prodejem nemovitostí
                </li>
              </ul>
            </div>
          </div>
        </div>
        <router-link to="/nova-nemovitost" class="bg-body-3 text-white px-5 py-3 rounded-md flex mx-auto mt-10 lg:mt-40" style="width: fit-content;">Vytvořte nemovitost</router-link>
      </div>
    </section>
    <section class="flex flex-col lg:max-w-screen-xl lg:mx-auto pt-10 lg:pt-24 pb-20 lg:pb-40">
      <h2 class="text-4xl font-bold text-center mb-10 lg:mb-24">Hodnocení zákazníků</h2>
      <div class="flex flex-col lg:grid grid-cols-3 gap-7 px-5 xl:px-0">
        <div class="flex flex-col gap-7 border border-slate-800 rounded-md px-6 py-11">
          <div class="flex gap-5">
            <img class="rounded-full" width="70" src="/images/customers/customer-1.png" alt="customer 1" />
            <div class="flex flex-col gap-2">
              <p class="font-medium text-lg">Tomáš Souček</p>
              <div class="flex gap-5">
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
              </div>
            </div>
          </div>
          <h4 class="text-lg font-semibold">Jsem naprosto nadšený z aplikace MakléřeNechci.cz!</h4>
          <p style="line-height: 28px;">"Rozhraní aplikace je velmi intuitivní a přehledné, což mi umožnilo snadno sestavit inzerát a sdílet ho na různých platformách. Byl jsem příjemně překvapen i tím, že jsem získal mnohem více zájemců než při použití tradičních inzerčních metod. Jednoznačně doporučuji MakléřeNechci.cz každému, kdo chce prodat svou nemovitost bez zbytečného stresu a námahy."</p>
        </div>
        <div class="flex flex-col gap-7 bg-white rounded-md shadow-xl px-6 py-11">
          <div class="flex gap-5">
            <img class="rounded-full" width="70" src="/images/customers/customer-2.png" alt="customer 2" />
            <div class="flex flex-col gap-2">
              <p class="font-medium text-lg">Marek Vágner</p>
              <div class="flex gap-5">
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
              </div>
            </div>
          </div>
          <h4 class="text-lg font-semibold">MakléřeNechci.cz je skvělá aplikace pro vytváření inzerátů na prodej nemovitostí.</h4>
          <p style="line-height: 28px;">"Velmi rychle a snadno jsem vytvořil inzerát a okamžitě ho zveřejnil. Díky tomu jsem získal mnoho zájemců a nakonec se mi podařilo prodat mou nemovitost za skvělou cenu. Velmi doporučuji tuto aplikaci všem, kteří hledají snadný a účinný způsob, jak prodat svou nemovitost."</p>
        </div>
        <div class="flex flex-col gap-7 border border-slate-800 rounded-md px-6 py-11">
          <div class="flex gap-5">
            <img class="rounded-full" width="70" height="70" src="/images/customers/customer-3.png" alt="customer 3" />
            <div class="flex flex-col gap-2">
              <p class="font-medium text-lg">Tereza Bouchalová</p>
              <div class="flex gap-5">
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
                <img width="15" src="/images/star.png" />
              </div>
            </div>
          </div>
          <h4 class="text-lg font-semibold">MakléřeNechci.cz mi ušetřila mnoho času a energie při prodeji mé nemovitosti!</h4>
          <p style="line-height: 28px;">"Vytvoření inzerátu bylo velmi jednoduché a rychlé, a díky aplikaci jsem okamžitě získala mnoho zájemců. Nakonec jsem svůj majetek prodala za vysokou cenu a byla jsem velmi spokojená s celým procesem. Pokud hledáte snadný a efektivní způsob, jak prodat svou nemovitost, určitě vyzkoušejte MakléřeNechci.cz."</p>
        </div>
      </div>
    </section>
    <section id="registration" class="flex flex-col py-24" style="background-color: #e4f5fd;">
      <div class="lg:max-w-screen-lg lg:mx-auto">
        <h2 class="text-5xl font-bold text-center mb-5">Registrace</h2>
        <p class="text-xl font-bold text-center mb-20">Registrujte se zdarma do aplikace MakléřeNechci a k prodeji nemovitosti vás dělí jen několik kliknutí.</p>
        <div class="flex flex-col-reverse lg:grid grid-cols-2 gap-10 lg:gap-36 px-5 xl:px-0">
          <form class="flex flex-col gap-8" v-on:submit.prevent="register()">
            <div class="flex flex-col gap-2">
              <label htmlFor="firstNameAndLastName" class="required">Jméno a příjmení</label>
              <input id="firstNameAndLastName" v-model="userProfile.name" type="text" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="submitted && !$v.userProfile.name.required ? 'border-red-500' : ''" />
              <p v-if="submitted && !$v.userProfile.name.required" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </p>
            </div>
            <div class="flex gap-4">
              <div class="flex flex-col gap-2" style="width: 100px;">
                <label htmlFor="dialcode">Předvolba</label>
                <input id="dialcode" v-model="userProfile.code" @input="plusHandler()" type="text" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" placeholder="+420" />
              </div>
              <div class="flex flex-col gap-2 w-full">
                <label htmlFor="phoneNumber" class="required">Telefon</label>
                <input id="phoneNumber" autocomplete="off" v-model="userProfile.phone" v-mask="priceMask" type="text" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="submitted && !$v.userProfile.phone.required ? 'border-red-500' : ''" />
                <p v-if="submitted && !$v.userProfile.phone.required" class="font-medium text-sm text-red-500">
                  {{ $t("ErrorMessages.FieldIsRequired") }}
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <label htmlFor="email" class="required">Email</label>
              <input id="email" v-model="userProfile.email" @change="validateEmail()" type="text" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="(submitted || emailChecked) && (!$v.userProfile.email.required || !$v.userProfile.email.email || emailInDatabase) ? 'border-red-500' : ''" />
              <p v-if="(submitted || emailChecked) && !$v.userProfile.email.email" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.EmailBadFormat") }}
              </p>
              <div v-if="(submitted || emailChecked) && !$v.userProfile.email.required" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>
              <div v-if="emailInDatabase" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.EmailAlreadyExist") }}
                <p>
                  <button class="text-body-1" @click="login()">Přihlásit?</button>
                </p>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <label htmlFor="password" class="required">Heslo</label>
              <input id="password" v-model="userProfile.password" type="password" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="submitted && !$v.userProfile.password.required ? 'border-red-500' : ''" />
              <p v-if="submitted && !$v.userProfile.password.required" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </p>
              <p v-if="submitted && !$v.userProfile.password.minLength" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.PasswordMinLength") }}
              </p>
            </div>
            <div class="flex flex-col gap-2">
              <label htmlFor="passwordAgain" class="required">Zopakovat heslo</label>
              <input id="passwordAgain" v-model="userProfile.passwordAgain" type="password" class="bg-white border border-black py-4 w-full font-bold text-lg rounded-sm px-3" :class="(submitted && !$v.userProfile.passwordAgain.required) || (submitted && !$v.userProfile.passwordAgain.sameAsPassword) ? 'border-red-500' : ''" />
              <div v-if="submitted && !$v.userProfile.passwordAgain.required" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </div>

              <div v-if="submitted && !$v.userProfile.passwordAgain.sameAsPassword" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.PasswordsAreNotSame") }}
              </div>
            </div>
            <div class="flex items-center gap-2">
              <div class="flex items-center gap-2">
                <input id="gdprAgreement" v-model="userProfile.agree" type="checkbox" class="w-5 h-5 cursor-pointer" :style="submitted && userProfile.agree === false ? 'height: 34px; background: red; paddingTop: 1px; paddingLeft: 1px; paddingRight: 1px; borderRadius: 4px; ' : ''" />
                <label htmlFor="gdprAgreement">Souhlasím se zpracováním osobních údajů.</label>
              </div>
              <p v-if="submitted && userProfile.agree === false" class="font-medium text-sm text-red-500">
                {{ $t("ErrorMessages.FieldIsRequired") }}
              </p>
            </div>
            <button type="submit" class="bg-body-3 text-white py-4 w-full font-bold text-lg rounded-md">Zaregistrovat se zdarma</button>
          </form>
          <div class="flex flex-col justify-center gap-10">
            <img src="/images/registration-illustration.png" />
            <ul class="flex flex-col gap-16 text-2xl list">
              <li class="flex items-center gap-5 font-bold">
                <div class="w-6 h-6 bg-body-3 rounded-full flex justify-center items-center">
                  <img width="12" src="/images/checkmark.png" />
                </div>
                Prodáte svou nemovitost za co nejvyšší cenu
              </li>  
              <li class="flex items-center gap-5 font-bold">
                <div class="w-6 h-6 bg-body-3 rounded-full flex justify-center items-center">
                  <img width="12" src="/images/checkmark.png" />
                </div>
                Rychlý a bezpečný prodej
              </li> 
              <li class="flex items-center gap-5 font-bold">
                <div class="w-6 h-6 bg-body-3 rounded-full flex justify-center items-center">
                  <img width="12" src="/images/checkmark.png" />
                </div>
                Transparentnější prodej nemovitostí
              </li>  
            </ul>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import axios from "axios";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { getUserEmail } from "../services/rest/services";

const currencyPriceMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: false,
  allowNegative: false,
  integerLimit: 12,
});
export default {
  name: "LandingPage",
  data() {
    return {
      codeCantIncludes:
        "abcdefghcijklmnopqrstuvwxyzABCDEFGHCIJKLMNOPQRSTUVWXYZ",
      userProfile: {
        agree: false,
        code: "+420",
      },
      emailChecked: false,
      submitted: false,
      userJwt: "",
      emailInDatabase: false,
      priceMask: currencyPriceMask,
    };
  },
  methods: {
    async register() {
      this.emailInDatabase = false;
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || this.userProfile.agree === false) {
        return window.localStorage.setItem("registrace", false);
      } else {
        try {
          const emails = await getUserEmail(this.userProfile.email);
          if (emails.email) {
            window.localStorage.setItem("registrace", false);
            return (this.emailInDatabase = true);
          } else if (!emails.email) {
            if (this.userProfile.code === "+") {
              this.userProfile.code = "";
            }
            // cant includes letters!
            const letterArray = this.codeCantIncludes.split("");
            letterArray.forEach((letter) => {
              if (this.userProfile.code.includes(letter)) {
                this.userProfile.code = "";
              }
            });
            await axios.post(
              `${process.env.VUE_APP_STRAPI_API_URL}/auth/local/register`,
              {
                username: this.userProfile.email,
                email: this.userProfile.email,
                nameSurname: this.userProfile.name,
                password: this.userProfile.password,
                phone: this.userProfile.code + this.userProfile.phone,
              }
            );
            Swal.fire(this.$t("SuccessMessages.SuccessfullyRegistered"));
          }
          this.$router.push("/potvrzeni-registrace-emailem");
        } catch (e) {
          return e;
        }
      }
    },
    plusHandler() {
      if (this.userProfile.code.length === 0) {
        this.userProfile.code = "+";
      }
      if (this.userProfile.code.length > 5) {
        this.userProfile.code = "+";
      }
    },
    validateEmail() {
      this.emailChecked = true;
      this.$v.$touch();
    },
    login() {
      window.localStorage.clear();
      this.$router.push("prihlaseni");
    },
  },
  validations: {
    userProfile: {
      name: { required },
      phone: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      passwordAgain: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs("password"),
      },
      agree: { required },
    },
  },
};
</script>

<style scoped>
.list {
  @apply flex flex-col gap-7;
}
.list li {
  @apply grid items-center gap-5 font-bold;
  grid-template-columns: 20px 1fr;
}
.list-sell li {
  @apply grid border-b py-2 items-center lg:flex lg:justify-between;
  grid-template-columns: 1fr 100px;
}
.list-sell li p:last-child {
  @apply text-right;
}
.required::after {
  content: "*";
}
</style>
